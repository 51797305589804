import React from 'react'
import Content from '../components/DashboardC/Content'
import  ConsultarTransacciones  from '../components/minicomponentes/ConsultarTransacciones'

export const ConsultarTransaccionesPage = () => {
  return (
    <>
        <Content/>
        <ConsultarTransacciones/>
        
    </>
  )
}

export default ConsultarTransaccionesPage;