import React from "react";
import RecoveryAccount from "../components/RecoveryAccount/RecoverPassword";


export default function NotFoundPage() {
 return (

<div>
    <RecoveryAccount/>    
    </div>
    );
}