import React from "react";
import VerificationCode from "../components/RecoveryAccount/VerificationCode";

export default function VerificationCodePage() {
  return (
    <div>
      <VerificationCode />
    </div>
  );
}
