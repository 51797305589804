import React from "react";
import NotFound from "../components/notfoundcomponents/NotFound"; 


export default function NotFoundPage() {
 return (

<div>
    <NotFound/>    
    </div>
    );
}