// eslint-disable-next-line no-unused-vars
import React from "react";
import Login from "..//components/homeC/Login";

export default function HomePage() {
  return (
    <>
      <Login />
    </>
  );
}
