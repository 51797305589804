import React from 'react'
import Content from '../components/DashboardC/Content'
import Credenciales from '../components/minicomponentes/Credenciales'

export const IntegracionesPage = () => {
  return (
    <>
        <Content/>
        <Credenciales/>
        
    </>
  )
}

export default IntegracionesPage;