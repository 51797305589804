import React from 'react'
import Content from '../components/DashboardC/Content'
import RealizarTransaccion from '../components/minicomponentes/RealizarTransaccion'

export const RealizarTransaccionPage = () => {
  return (
    <>
        <Content/>
        <RealizarTransaccion/>
        
    </>
  )
}

export default RealizarTransaccionPage;