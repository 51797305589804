import React from 'react'
import AppBar from "../components/Appbar/Header/AppBar";
import LandingPageValinkPay from "../components/ComponentesHome/LandingPageValinkPay";


const Home = () => {
  return (
    <>
      < AppBar />
      <LandingPageValinkPay />
    </>
  )
}

export default Home