import React from "react";
import FormRegister from "../components/registerC/FormRegister";


export default function RegisterPage() {
 return (

<div>

    <FormRegister/>    
    </div>
    );
}