import React from 'react'
import Content from '../components/DashboardC/Content'
import DashboardMain from '../components/minicomponentes/DashboardMain'

export const DashboardUsers = () => {
  return (
    <>
      <Content />
      <DashboardMain/>
    </>
  )
}
